import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import appleIcon from '../static/images/icon-apple.png'
import { apkDownloadUrl } from '../utils/url'

export default function AbWalletContent() {
  const intl = useIntl()
  return (
    <div id={'abwallet'}>
      <div className='container'>
        <div className={'abwallet-content'}>
          <ul>
            <li className={'wallet-desc'}>
              <h1>{ intl.formatMessage({ id: 'AB Wallet' }) }</h1>
              <h3>{ intl.formatMessage({ id: 'Decentralized Digital Wallet Of' }) }</h3>
              <h3>{ intl.formatMessage({ id: 'Newton Community Economy' }) }</h3>
              <div className={'building'}>
                <a href="https://itunes.apple.com/app/newpay/id1439660801">
                  <img src={appleIcon} className='apple-icon' />
                  <span>{ intl.formatMessage({ id: 'Download on the' }) }<br /><b>App Store</b></span>
                </a>
                <a href={ apkDownloadUrl }>
                  <b>{ intl.formatMessage({ id: 'Download' }) }APK</b>
                </a>
              </div>
            </li>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'stake-img'}
                  alt="img"
                  src="../static/images/abwallet/wallet.png"
                />
              </div>
            </li>
          </ul>
        </div>
        <div className={'abwallet-content'}>
          <ul>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'stake-img'}
                  alt="img"
                  src="../static/images/abwallet/digit.png"
                />
              </div>
            </li>
            <li className={'wallet-desc'}>
              <h2>{ intl.formatMessage({ id: 'Digital Wallet' }) }</h2>
              <p>{ intl.formatMessage({ id: 'Multiple Tokens Support.' }) }</p>
              <p>{ intl.formatMessage({ id: 'Fast & Secure.' }) }</p>
            </li>
          </ul>
        </div>
        {/* <div className={'abwallet-content'}>
          <ul>
            <li className={'wallet-desc'}>
              <h2>{ intl.formatMessage({ id: 'NewSwap Exchange' }) }</h2>
              <p>{ intl.formatMessage({ id: 'Decentralized Token Swap Protocol.' }) }</p>
              <p>{ intl.formatMessage({ id: 'Easily Swap Tokens On Your Phone.' }) }</p>
            </li>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'stake-img'}
                  alt="img"
                  src="../static/images/abwallet/exchange.png"
                />
              </div>
            </li>
          </ul>
        </div>
        <div className={'abwallet-content'}>
          <ul>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'stake-img'}
                  alt="img"
                  src="../static/images/abwallet/mining.png"
                />
              </div>
            </li>
            <li className={'wallet-desc'}>
              <h2>{ intl.formatMessage({ id: 'NewSwap Mining' }) }</h2>
              <p>{ intl.formatMessage({ id: 'Get Mining Rewards by Contributing To Newton Community Economy.' }) } </p>
              <p>{ intl.formatMessage({ id: 'Stake Liquidity Tokens To Participate In Mining.' }) }</p>
            </li>
          </ul>
        </div>
        <div className={'abwallet-content'}>
          <ul>
            <li className={'wallet-desc'}>
              <h2>NewBridge</h2>
              <p>{ intl.formatMessage({ id: 'Transfer Assets Across Different Blockchains.' }) }</p>
              <p>{ intl.formatMessage({ id: 'Now Supports Transfer of Main-stream Assets Between Ethereum and NewChain.' }) }</p>
            </li>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'stake-img'}
                  alt="img"
                  src="../static/images/abwallet/newbridge.png"
                />
              </div>
            </li>
          </ul>
        </div> */}
      </div>
      <CentenMobile />
    </div>
  )
}

const CentenMobile = () => {
  const intl = useIntl()
  return (
    <div className='container'>
    <div className={'abwallet-h5'}>
      <ul>
        <li className={'wallet-desc'}>
          <h1>{ intl.formatMessage({ id: 'AB Wallet' }) }</h1>
          <h3>{ intl.formatMessage({ id: 'Decentralized Digital Wallet Of' }) }</h3>
          <h3>{ intl.formatMessage({ id: 'Newton Community Economy' }) }</h3>
        </li>
        <li>
          <div>
            <StaticImage
              placeholder="blurred"
              className={'stake-img'}
              alt="img"
              src="../static/images/abwallet/wallet.png"
            />
          </div>
        </li>
          <div className={'building'}>
            <a href="https://itunes.apple.com/app/newpay/id1439660801">
              <img src={appleIcon} className='apple-icon' />
              <span>{ intl.formatMessage({ id: 'Download on the' }) }<br /><b>App Store</b></span>
            </a>
            <a href={ apkDownloadUrl }>
              <b>{ intl.formatMessage({ id: 'Download' }) } APK</b>
            </a>
          </div>
      </ul>
    </div>
    <div className={'abwallet-h5'}>
      <ul>
        <li className={'wallet-desc'}>
          <h2>{ intl.formatMessage({ id: 'Digital Wallet' }) }</h2>
          <p>{ intl.formatMessage({ id: 'Multiple Tokens Support.' }) }</p>
          <p>{ intl.formatMessage({ id: 'Fast & Secure.' }) }</p>
        </li>
        <li>
          <div>
            <StaticImage
              placeholder="blurred"
              className={'stake-img'}
              alt="img"
              src="../static/images/abwallet/digit.png"
            />
          </div>
        </li>
      </ul>
    </div>
    {/* <div className={'abwallet-h5'}>
      <ul>
        <li className={'wallet-desc'}>
          <h2>{ intl.formatMessage({ id: 'NewSwap Exchange' }) }</h2>
          <p>{ intl.formatMessage({ id: 'Decentralized Token Swap Protocol.' }) }</p>
          <p>{ intl.formatMessage({ id: 'Easily Swap Tokens On Your Phone.' }) }</p>
        </li>
        <li>
          <div>
            <StaticImage
              placeholder="blurred"
              className={'stake-img'}
              alt="img"
              src="../static/images/abwallet/exchange.png"
            />
          </div>
        </li>
      </ul>
    </div>
    <div className={'abwallet-h5'}>
      <ul>
        <li className={'wallet-desc'}>
          <h2>{ intl.formatMessage({ id: 'NewSwap Mining' }) }</h2>
          <p>{ intl.formatMessage({ id: 'Get Mining Rewards by Contributing To Newton Community Economy.' }) } </p>
          <p>{ intl.formatMessage({ id: 'Stake Liquidity Tokens To Participate In Mining.' }) }</p>
        </li>
        <li>
          <div>
            <StaticImage
              placeholder="blurred"
              className={'stake-img'}
              alt="img"
              src="../static/images/abwallet/mining.png"
            />
          </div>
        </li>
      </ul>
    </div>
    <div className={'abwallet-h5'}>
      <ul>
        <li className={'wallet-desc'}>
          <h2>NewBridge</h2>
          <p>{ intl.formatMessage({ id: 'Transfer Assets Across Different Blockchains.' }) }</p>
          <p>{ intl.formatMessage({ id: 'Now Supports Transfer of Main-stream Assets Between Ethereum and NewChain.' }) }</p>
        </li>
        <li>
          <div>
            <StaticImage
              placeholder="blurred"
              className={'stake-img'}
              alt="img"
              src="../static/images/abwallet/newbridge.png"
            />
          </div>
        </li>
      </ul>
    </div> */}
    </div>
  )
}
import React from 'react'
import AbWalletContent from '../components/abWalletContent'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'

export default AbWallet

function AbWallet() {
  let pageModel = new PageModelTitle('AB Wallet', 'abwallet-html')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  return (
    <div>
      <AbWalletContent />
    </div>
  )
}